<template>
	<v-navigation-drawer v-model="isDrawer" app bottom temporary stateless right :width="dialogWith">
		<v-card text elevation="0">
			<div class="dialog-custom-header header-sticky">
				<v-card-title class="border-bottom-grey">
					<span class="text-uppercase custom-header-blue-text"> Notification </span>
					<v-badge
						v-if="notification_count"
						color="green"
						:content="notification_count"
						inline
						style="font-size: 15px"
					>
					</v-badge>
					<v-spacer></v-spacer>
					<v-btn class="mr-3" icon small @click="updateDialogWith()">
						<v-icon size="30">mdi-fullscreen-exit</v-icon>
					</v-btn>
					<v-btn icon small @click="$emit('close', false)">
						<v-icon size="30">mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-layout class="py-2 px-5">
					<v-menu v-model="filterMenu" :close-on-content-click="false" offset-y z-index="9999999">
						<template v-slot:activator="{ on, attrs }">
							<v-btn v-bind="attrs" v-on="on" depressed color="blue darken-4" text>
								<v-icon left>mdi-filter-outline</v-icon>
								Filters
							</v-btn>
						</template>

						<v-card width="250">
							<div class="px-5 py-2 text-h6">
								Filter By
								<div class="mt-2">
									<v-text-field
										class="rounded"
										outlined
										hide-details
										placeholder="Search..."
										dense
										v-model="searchString"
									></v-text-field>
								</div>
							</div>
							<v-list class="pt-0">
								<template v-for="row in filterModuleList">
									<v-list-item :key="row.text">
										<v-list-item-icon size="35" class="ma-0">
											<v-checkbox dense hide-details v-model="row.check" class="m-0"></v-checkbox>
										</v-list-item-icon>
										<v-list-item-content>
											<v-list-item-title class="text-uppercase">{{ row.text }}</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
								</template>
							</v-list>

							<v-divider class="my-0"></v-divider>

							<v-card-actions class="pa-5">
								<v-spacer></v-spacer>
								<v-btn
									depressed
									outlined
									@click="
										filterMenu = false;
										resetFilter();
									"
								>
									Clear
								</v-btn>
								<v-btn
									depressed
									color="blue darken-4"
									class="white--text"
									@click="
										filterMenu = false;
										getNotification();
									"
								>
									Apply Filter
								</v-btn>
								<v-spacer></v-spacer>
							</v-card-actions>
						</v-card>
					</v-menu>
					<template v-if="isFilter()">
						<v-btn
							min-width="30"
							height="26"
							text
							color="red lighten-1"
							class="white--text ml-2"
							@click="resetFilter()"
						>
							<span class="text-capitalize"> clear Filter </span>
						</v-btn>
					</template>
					<v-spacer></v-spacer>
					<template v-if="Array.isArray(selectedNotification) && selectedNotification.length">
						<v-btn
							min-width="30"
							height="26"
							text
							color="red lighten-1"
							class="white--text mr-2"
							@click="selectedNotification = []"
						>
							<span class="text-capitalize"> clear selection </span>
						</v-btn>
						<v-btn
							min-width="30"
							width="30"
							height="26"
							small
							outlined
							color="red lighten-1"
							class="white--text mr-2"
							@click="deleteNotification"
						>
							<v-icon>mdi-delete-outline</v-icon>
						</v-btn>
						<v-btn depressed color="blue darken-4" class="white--text" @click="markAsRead">
							Mark as read
						</v-btn>
					</template>
					<template v-else>
						<v-btn depressed color="blue darken-4" class="white--text" @click="markAllAsRead">
							Mark all as read
						</v-btn>
					</template>
				</v-layout>
			</div>

			<v-card-text class="px-3 py-3">
				
				<template v-if="Array.isArray(notifications) && notifications.length">
					
					<div v-for="(row, index) in notifications" :key="index + 'data'">
						<div class="text-center pt-3 pb-9" v-if="row.is_divider">
							<div class="border-bottom">
								<strong style="position: relative; top: 9.5px; background: #fff; padding: 2px 12px">
									{{ row.date }}
								</strong>
							</div>
						</div>
						<div
							v-else
							class="d-flex px-2 py-3 mb-2 rounded"
							:class="{ 'grey lighten-4': !row.is_marked }"
						>
							<div class="" style="width: 40px">
								<v-checkbox
									dense
									hide-details
									class="m-0"
									v-model="selectedNotification"
									:value="row.id"
									multiple
								></v-checkbox>
							</div>
							<div class="flex-grow-1">
								<b>{{ row.message }}</b>
								<p v-html="row.description"></p>
								<div class="text-muted pt-1">
									<em>{{ row.datetime }}</em>
									<v-chip outlined color="blue darken-4" x-small label class="px-1 ml-2" v-if="row?.module">
										<span class="text-uppercase"> {{ row.module }} </span>
									</v-chip>
									<!-- <v-icon size="18" class="cursor-pointer ms-3" color="blue">mdi-open-in-new</v-icon> -->
								</div>
							</div>
						</div>
					</div>
				</template>
				<template v-else>
					<div class="text-center text-h4 my-10">
						<v-icon size="100">mdi-bell-off-outline</v-icon>
						<div class="py-5">No Notifications</div>
					</div>
				</template>
			</v-card-text>
		</v-card>
	</v-navigation-drawer>
</template>
<script>
import { QUERY, PATCH } from "@/core/services/store/request.module";
import { SET_MESSAGE,SET_NOTIFICATION_COUNT /* SET_ERROR */ } from "@/core/services/store/common.module";
export default {
	name: "NotificationDrawer",
	data() {
		return {
			isDrawer: false,
			dialogWith: "40%",
			filterMenu: false,
			selectedNotification: [],
			searchString: "",
			notification_count: 0,
			filterModuleList: [
				{
					text: "Certificate",
					value: "certificate",
					check: false,
				},
				{
					text: "Tagging",
					value: "tagging",
					check: false,
				},
				/*{
					text: "Customer",
					value: "customer",
					check: false,
				},
				{
					text: "Reminder",
					value: "reminder",
					check: false,
				},
				{
					text: "Meeting",
					value: "meeting",
					check: false,
				},
				{
					text: "Leave",
					value: "leave",
					check: false,
				},
				{
					text: "Claim",
					value: "claim",
					check: false,
				},*/
			],
			notifications: [
				// {
				// 	id: 1,
				// 	message: "Lorem ipsum dolor sit",
				// 	description:
				// 		"Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus, ipsa! Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus, ipsa",
				// 	module: "lead",
				// 	user: 1,
				// 	is_marked: 0,
				// 	added_by: 0,
				// 	datetime: "2023-11-22 08:54:42",
				// 	user_name: "Super Admin",
				// 	profile_img: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
				// 	added_at: "23 hours ago",
				// },
				// {
				// 	is_divider: true,
				// 	date: "12 MARCH 2023",
				// },
			],
		};
	},
	props: {
		drawer: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		drawer(param) {
			this.isDrawer = param;
			if (param) {
				this.getNotification();
			}
		},
	},
	methods: {
		isFilter() {
			if (this.searchString) {
				return true;
			}
			const selectedModule = this.filterModuleList.filter((row) => {
				return row.check;
			});
			if (selectedModule.length) {
				return true;
			}
			return false;
		},
		resetFilter() {
			this.searchString = null;
			const _module = this.filterModuleList.map((row) => {
				return { ...row, check: false };
			});
			this.filterModuleList = _module;
			this.getNotification();
		},
		getNotification() {
			this.loading = true;
			const selectedModule = this.filterModuleList
				.filter((row) => {
					return row.check;
				})
				.map((row) => row.value)
				.join(",");

			this.$store
				.dispatch(QUERY, {
					url: `all-notifications`,
					data: {
						status: "all",
						search: this.searchString,
						module: selectedModule,
					},
				})
				.then((data) => {
					//console.log(data);
					this.notifications = data?.data?.notifications;
					this.notification_count = data?.data?.total_notifications;
					this.$store.commit(SET_NOTIFICATION_COUNT, data?.data?.unread);
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.loading = false;
				});
		},
		markAllAsRead() {
			this.selectedNotification = this.notifications.filter((row) => row.id).map((row) => row.id);
			if (this.selectedNotification.length) {
				this.markAsRead();
			}
		},
		markAsRead() {
			this.loading = true;
			this.$store
				.dispatch(PATCH, {
					url: `all-notifications/bulk-action`,
					data: {
						action: "mark_as_read",
						selected: this.selectedNotification,
					},
				})
				.then((data) => {
					console.log(data);
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: `Success ! Notification successfully updated.` },
					]);
					this.selectedNotification = [];
					this.getNotification();
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.loading = false;
				});
		},
		deleteNotification(){
			this.loading = true;
			this.$store
				.dispatch(PATCH, {
					url: `notifications/delete`,
					data: {
						selected: this.selectedNotification,
					},
				})
				.then((data) => {
					console.log(data);
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: `Success ! Notification successfully deleted.` },
					]);
					this.selectedNotification = [];
					this.getNotification();
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.loading = false;
				});
		},
		updateDialogWith() {
			if (this.dialogWith == "80%") {
				this.dialogWith = "760";
			} else {
				this.dialogWith = "80%";
			}
		},
	},
	// computed:{
	// 	...mapGetters([
	// 		"notificationCountUnread"
	// 	])
	// },
	mounted() {
		this.getNotification();
	},
};
</script>
<style>
.header-sticky {
	position: sticky;
	top: 0px;
	background: #fff;
	border-top: 8px solid #f57c00 !important;
	border-radius: 0 !important;
	z-index: 1;
}
.border-bottom-grey {
	border-bottom: 1px solid lightgrey;
}
</style>
